import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useScreenStore } from '../stores/useScreenStore';
import { useWindowSizeStore } from '../stores/useWindowSizeStore';

interface Props {
  children: ReactNode;
}

function getDeviceType(width: number) {
  if (width >= 1669) {
    return '3xl';
  } else if (width >= 1400) {
    return 'xl';
  } else {
    return 'mobile';
  }
}

function getScreens(width: number) {
  const device = getDeviceType(width);

  return {
    '3xl': device === '3xl',
    xl: device === 'xl' || device === '3xl',
    mobile: device === 'mobile',
  };
}

export function WindowHandlerWrapper({ children }: Props): ReactElement {
  const setSize = useWindowSizeStore(state => state.setSize);
  const setScreen = useScreenStore(state => state.setScreen);

  const handleResize = useCallback(() => {
    setSize(window.innerWidth, window.innerHeight);
    setScreen(getScreens(window.innerWidth));
  }, [setScreen, setSize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, setScreen, setSize]);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  return <>{children}</>;
}
