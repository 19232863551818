import { ReactElement } from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

export function UserGroupIcon({
  width = '15',
  height = '12',
  className,
}: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 15 12"
    >
      <path
        className={className}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.833 11.333h3.334V9.999a2 2 0 00-3.571-1.238m.237 2.572H4.167m6.666 0V9.999a3.32 3.32 0 00-.237-1.238m0 0a3.334 3.334 0 00-6.192 0m-.237 2.572H.833V9.999a2 2 0 013.571-1.238m-.237 2.572V9.999c0-.437.084-.855.237-1.238M9.5 2.666a2 2 0 11-4 0 2 2 0 014 0zm4 2a1.333 1.333 0 11-2.666 0 1.333 1.333 0 012.666 0zm-9.333 0a1.333 1.333 0 11-2.667 0 1.333 1.333 0 012.667 0z"
      ></path>
    </svg>
  );
}
