import { ReactElement } from 'react';

import clsx from 'clsx';

interface Props {
  label: string;
  value: string;
  valueClassName?: string;
  emphasisText?: string;
  largeText?: boolean;
}

export function LabelledInformationProposal({
  label,
  value,
  emphasisText,
  valueClassName,
  largeText = false,
}: Props): ReactElement {
  return (
    <li className="flex flex-col">
      <label htmlFor={label} className="uppercase text-xs text-[#777777]">
        {label}
      </label>
      <div className="mt-0.5">
        <span
          id={label}
          className={clsx(
            `text-base text-[#394146] font-medium inline ${valueClassName}`,
            largeText ? 'min-[748px]:text-xl' : 'text-base',
          )}
        >
          {value}
          {emphasisText && (
            <strong className="text-secondary-main font-medium">
              {' '}
              - {emphasisText}
            </strong>
          )}
        </span>
      </div>
    </li>
  );
}
