import { ReactElement } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

import { Button } from './Button';
import { ArrowRightIcon } from './icons/ArrowRightIcon';

const styles = cva(
  'mb-11 bg-cover mt-20 lg:min-h-[927px] min-h-[500px] max-[1400px]:flex max-[1400px]:flex-col',
  {
    variants: {
      page: {
        ouvidoria: 'bg-ouvidoria',
        dentist: 'bg-dentist',
        beneficiary: 'bg-beneficiary',
        companies: 'bg-companies',
      },
    },
    defaultVariants: {
      page: 'ouvidoria',
    },
  },
);

interface Props extends VariantProps<typeof styles> {
  title: string;
  href?: string;
  hideBtn?: boolean;
  hideText?: boolean;
  mainText?: string;
  imgSource?: string;
}

export default function BigBanner({
  title,
  page,
  mainText,
  hideBtn = false,
  hideText = false,
  href = '#',
}: Props): ReactElement {
  return (
    <section className={styles({ page })}>
      <div className="flex justify-center">
        <div className="w-[40%] max-[1400px]:w-[70%] rounded-b-[36px] max-[1400px]:rounded-b-[100px] text-center bg-primary-main p-[20px]">
          <h2 className="text-primary-container2 text-[1.5rem]">{title}</h2>
        </div>
      </div>
      <div className="flex justify-between pr-[100px] pl-[100px] items-center max-[1400px]:p-4 max-[1400px]:block mt-auto">
        <img
          className="invisible max-[1400px]:hidden"
          src="/assets/images/icons/wave.png"
        />
        <div>
          {!hideText && (
            <h3 className="text-neutral-with text-[2.5rem] max-[1400px]:text-[1.5rem] leading-[3.75rem] max-[1400px]:leading-normal min-[1400px]:text-right max-w-[685px]">
              {mainText}
            </h3>
          )}
          {!hideBtn && (
            <a href={href}>
              <Button
                text="Ir para atendimento"
                className="min-[1400px]:ml-auto mt-10"
                intent="quaternary"
                Icon={ArrowRightIcon}
              />
            </a>
          )}
        </div>
      </div>
    </section>
  );
}
