import { ReactElement } from 'react';
import { SuspendedCardItem } from './SuspendedCardItem';

export function SuspendedCards(): ReactElement {
  return (
    <ul className="grid grid-cols-3 max-w-[1516px] w-full -mt-24 mx-auto sm:gap-6 gap-2 px-4 relative z-10">
      <SuspendedCardItem
        imgSource="/assets/images/suspended-cards/card-1.png"
        link="#individual"
        alt="imagem ilustrativa de uma pessoa"
        text={
          <>
            Planos a partir de <strong className="font-semibold">41,90</strong>
            /mensais
          </>
        }
        textButton="Ver planos"
      />
      <SuspendedCardItem
        text={<>Planos para sua empresa</>}
        textButton="Ver planos"
        link="#empresa"
        alt="imagem ilustrativa de uma empresa"
        imgSource="/assets/images/suspended-cards/card-2.png"
      />
      <SuspendedCardItem
        text={<>Redes credenciadas</>}
        textButton="Ver rede"
        link={process.env.REACT_APP_REDE_CREDENCIADA}
        alt="imagem ilustrativa com o mapa do brasil"
        justify="center"
        imgSource="/assets/images/suspended-cards/card-3.png"
      />
    </ul>
  );
}
