import { ReactElement } from 'react';

import { useHirePlanFormStepStore } from '../../../stores/useHirePlanFormStepStore';
import { ArrowLeftIcon } from '../../icons/ArrowLeftIcon';
import { breadcrumbSteps, bussinessBreadcrumbSteps } from '../data/breadcrumb';
import { useRouteLoaderData } from 'react-router-dom';

export function HirePlanMobileBreadcrumb(): ReactElement {
  const individualPlanData: any = useRouteLoaderData('token');
  const bussinessPlanData: any = useRouteLoaderData('token-empresa');
  let data = individualPlanData;
  if (individualPlanData == undefined) {
    data = bussinessPlanData;
  } else {
    data = individualPlanData;
  }
  const step = useHirePlanFormStepStore(state => state.step);
  const goToPreviousStep = useHirePlanFormStepStore(
    state => state.goToPreviousStep,
  );

  const [currentStep] =
    data.type == 'individual'
      ? breadcrumbSteps.filter(item => item.step === step)
      : bussinessBreadcrumbSteps.filter(item => item.step === step);

  return (
    <div className="w-full flex flex-col gap-11 mb-11">
      <div>
        <button onClick={goToPreviousStep} className="flex gap-2">
          <ArrowLeftIcon className="stroke-[#5C6770]" />
          <span className="text-[#5C6770] text-sm">
            Etapa {step} de {breadcrumbSteps.length}
          </span>
        </button>
      </div>
      <div className="flex flex-col items-center gap-1">
        <h2 className="text-xl text-primary-main font-bold">
          {currentStep.name}
        </h2>
        {currentStep.description && (
          <p className="text-secondary-main text-center mb-3">
            {currentStep.description}
          </p>
        )}
        <div className="h-1 w-[78px] bg-secondary-main" />
      </div>
    </div>
  );
}
