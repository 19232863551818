import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import Toggle from 'react-toggle';

import 'react-toggle/style.css';
import { useHirePlanForm } from '../../../contexts/HirePlanFormProvider';
import '../../../styles/toggle-styles.css';
import { Input } from '../../Input';
import { GoForwardButton } from '../GoForwardButton';

export function FinancialResponsibleForm(): ReactElement {
  const {
    watch,
    handleSubmit,
    goToNextForm,
    control,
    register,
    errors,
    unregister,
  } = useHirePlanForm();

  const beneficiaryIsSameAsFinancialResponsible = watch(
    'user.beneficiaryIsSameAsFinancialResponsible',
  );

  function handleToggleFinancialResponsible() {
    unregister('user.beneficiaryIsSameAsFinancialResponsible');
    unregister('user.financialResponsible.fullName');
    unregister('user.financialResponsible.email');
    unregister('user.financialResponsible.cpf');
    unregister('user.financialResponsible.birthDate');
    unregister('user.financialResponsible.address.cep');
    unregister('user.financialResponsible.address.neighborhood');
    unregister('user.financialResponsible.address.street');
    unregister('user.financialResponsible.address.number');
  }

  return (
    <form
      className="max-w-[755px] w-full"
      onSubmit={handleSubmit(goToNextForm)}
    >
      <h2 className="max-[748px]:hidden text-[2rem] text-primary-main font-semibold mb-8 text-center">
        Dados do responsável financeiro
      </h2>
      <div className="flex min-[748px]:items-center gap-6 min-[748px]:px-8 py-3 justify-center">
        <label className="flex">
          <Controller
            control={control}
            name="user.beneficiaryIsSameAsFinancialResponsible"
            render={({ field }) => (
              <Toggle
                defaultChecked={field.value}
                onChange={e => {
                  field.onChange(e.target.checked);
                  //register('beneficiaryIsSameAsFinancialResponsible');
                  handleToggleFinancialResponsible();
                }}
                icons={false}
                className="custom-classname"
                ref={field.ref}
              />
            )}
          />
        </label>
        <span className="block text-neutral-700">
          Beneficiário é o responsável{' '}
          <strong className="font-semibold">financeiro</strong>
        </span>
      </div>

      {!beneficiaryIsSameAsFinancialResponsible && (
        <div className="flex flex-col gap-8 mt-8">
          <div className="grid min-[748px]:grid-cols-2 grid-cols-1 gap-8">
            <Input
              label="Nome completo"
              placeholder="Nome completo"
              errorMessage={
                errors.user?.financialResponsible?.fullName?.message
              }
              {...register('user.financialResponsible.fullName')}
            />
            <Input
              label="E-mail"
              placeholder="E-mail"
              errorMessage={errors.user?.financialResponsible?.email?.message}
              {...register('user.financialResponsible.email')}
            />
          </div>
          <div className="grid min-[748px]:grid-cols-3 grid-cols-1 gap-8">
            <Input
              activateMask
              mask="999.999.999-99"
              label="CPF"
              placeholder="CPF"
              errorMessage={errors.user?.financialResponsible?.cpf?.message}
              {...register('user.financialResponsible.cpf')}
            />
            <div className="grid min-[748px]:grid-cols-2 grid-cols-1 gap-8 col-span-2">
              <Input
                activateMask
                mask="99/99/9999"
                label="Data de nascimento"
                placeholder="Data de nascimento"
                errorMessage={
                  errors.user?.financialResponsible?.birthDate?.message
                }
                {...register('user.financialResponsible.birthDate')}
              />
              <Input
                activateMask
                mask="99999-999"
                label="CEP"
                placeholder="CEP"
                errorMessage={
                  errors.user?.financialResponsible?.address?.cep?.message
                }
                {...register('user.financialResponsible.address.cep')}
              />
            </div>
          </div>
          <div className="grid min-[748px]:grid-cols-2 grid-cols-1 gap-8 w-full">
            <Input
              label="Bairro"
              placeholder="Bairro"
              errorMessage={
                errors.user?.financialResponsible?.address?.neighborhood
                  ?.message
              }
              {...register('user.financialResponsible.address.neighborhood')}
            />
            <Input
              label="Logradouro"
              placeholder="Logradouro"
              errorMessage={
                errors.user?.financialResponsible?.address?.street?.message
              }
              {...register('user.financialResponsible.address.street')}
            />
          </div>
          <div className="grid min-[748px]:grid-cols-3 grid-cols-1 gap-8">
            <div className="col-span-1 w-full">
              <Input
                label="Número"
                placeholder="Número"
                errorMessage={
                  errors.user?.financialResponsible?.address?.number?.message
                }
                {...register('user.financialResponsible.address.number')}
              />
            </div>
            <div className="min-[748px]:col-span-2 w-full">
              <Input
                label="Complemento"
                placeholder="Complemento"
                errorMessage={
                  errors.user?.financialResponsible?.address?.complement
                    ?.message
                }
                {...register('user.financialResponsible.address.complement')}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}
