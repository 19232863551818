import { ReactElement } from 'react';

interface Props {
  className?: string;
}

export function ArrowLeftIcon({ className }: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={`stroke-primary-main ${className}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 12h18M7 16l-4-4 4 4zm-4-4l4-4-4 4z"
      ></path>
    </svg>
  );
}
