export enum IndividualWithoutShortageTokens {
  CLINICO = 'bhJfBDp', //714 inativo
  DOC = 'y0c6NN9', //789 inativo
  ORTO = 's501FqS', //712 inativo
  PROTESE = 'TlWnqLm', //795 inativo
}

export enum IndividualWithShortageTokens {
  CLINICO = 'LKKzNlc', //709
  DOC = 'b6Qgseq', //945 NA
  ORTO = 'MHUPnXO', //713
  PROTESE = 'N94uYbj', //796 NA
}

export enum IndividualWithShortageNorthEastTokens {
  CLINICO = '1rtDA2U', //947
  DOC = 'b6Qgseq', //945 NA
  ORTO = 'jOPApmE', //946
  PROTESE = 'N94uYbj', //796 NA
}

export enum BussinessTypeRangeLives1Tokens {
  CLINICO = 'oKq5BIe', //719
  DOC = 'JzzNwnl', //864
  ORTO = 'XnNj1rk', //720
}

export enum BussinessTypeRangeLives2Tokens {
  CLINICO = 'IsI7djS', //721
  DOC = 'rGUCEGO', //866
  ORTO = 'smjRgCfFD', //722
}
