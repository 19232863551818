import { ReactElement } from 'react';

export function FoundDentistPlanCard(): ReactElement {
  return (
    <div className="shadow-[0px_2px_10px_rgba(58,39,179,0.10)] min-[1240px]:h-[231px] h-[245px] min-[1240px]:min-w-[181px] min-w-[150px] p-3 bg-white rounded-lg">
      <div className="bg-[#F7F6FF] h-full flex flex-col justify-center items-center">
        <img
          src="/assets/images/icons/planos/clinico.svg"
          alt="Vetor gráfico"
        />
        <span className="text-primary-main text-[1.25rem] mt-2 mb-3 text-center">
          Plano Clínico
        </span>
        <span className="text-sm text-medium-emphasis">A partir de</span>
        <div className="flex flex-col justify-center items-center -mt-1">
          <span className="text-secondary-main">
            <strong className="block">
              <span>R$</span>
              <span className="text-[2rem]">41,90</span>
            </strong>
          </span>
          <span className="text-secondary-main -mt-2">por mês</span>
        </div>
      </div>
    </div>
  );
}
