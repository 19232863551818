import { ReactElement } from 'react';

interface Props {
  title: string;
  text: string;
}

export function DependentCardInfo({ title, text }: Props): ReactElement {
  return (
    <div>
      <label className="uppercase block text-xs text-[#777777]" htmlFor={title}>
        {title}
      </label>
      <p id={title} className="text-high-emphasis">
        {text}
      </p>
    </div>
  );
}
