import { ReactElement, useEffect, useState } from 'react';

import { BannerImage } from '../components/BannerImage';
import { BenefitsSection } from '../components/BenefitsSection';
import { ContactSection } from '../components/ContactSection';
import { FindDentistsSection } from '../components/FindDentistsSection';
import { MobileAppSection } from '../components/MobileAppSection';
import { NetworkSection } from '../components/NetworkSection';
import { PageHelmet } from '../components/PageHelmet';
import { PlansSection } from '../components/PlansSection';
import { PublicServantsPlans } from '../components/PlansSection/PublicServantsPlans';
import { SuspendedCards } from '../components/SuspendedCards';

export function HomePage(): ReactElement {
  return (
    <div>
      <PageHelmet title="OdontoGroup | Saúde começa aqui" />

      <BannerImage page="home" />
      <SuspendedCards />
      <FindDentistsSection />
      <PlansSection />
      <ContactSection />
      {/* <PublicServantsPlans /> */}
      <BenefitsSection />
      <NetworkSection />
      <MobileAppSection />
    </div>
  );
}
