import { create } from 'zustand';

type MobileMenuState = {
  isOpen: boolean;
  closeMenu: () => void;
  toggleMenu: () => void;
};

export const useMobileMenuStore = create<MobileMenuState>(set => ({
  isOpen: false,
  closeMenu: () => set(() => ({ isOpen: false })),
  toggleMenu: () => set(state => ({ isOpen: !state.isOpen })),
}));
