import { AnnotationIcon } from '../components/icons/AnnotationIcon';
import { BriefCaseIcon } from '../components/icons/BriefCaseIcon';
import { HeartIcon } from '../components/icons/HeartIcon';
import { HomeIcon } from '../components/icons/HomeIcon';
import { SpeakerPhoneIcon } from '../components/icons/SpeakerPhoneIcon';
import { UserGroupIcon } from '../components/icons/UserGroupIcon';

export const navigation = [
  {
    name: 'Início',
    Icon: HomeIcon,
    href: '/',
  },
  {
    name: 'Beneficiário',
    Icon: HeartIcon,
    // href: process.env.REACT_APP_S4E_ASSOCIADO ?? '/',
    href: '/beneficiario',
  },
  {
    name: 'Empresas',
    Icon: BriefCaseIcon,
    // href: process.env.REACT_APP_S4E_EMPRESA ?? '/',
    href: '/empresas',
  },
  {
    name: 'Dentista',
    Icon: UserGroupIcon,
    href: '/dentist',
  },
  {
    name: 'Prestador de serviço',
    Icon: UserGroupIcon,
    href: '/prestador-de-serviço',
    subPages: [
      {
        name: 'Corretor',
        href: process.env.REACT_APP_CORRETOR ?? '/',
        //prestador-de-serviço/corretor
      },
      {
        name: 'Clínica',
        href: process.env.REACT_APP_CLINICA ?? '/',
        //prestador-de-serviço/clínica
      },
      {
        name: 'Dentistas',
        href: process.env.REACT_APP_DENTISTA ?? '/',
        //prestador-de-serviço/dentistas
      },
    ],
  },
  {
    name: 'FAQ',
    Icon: AnnotationIcon,
    href: '/faq',
  },
  /* {
    name: 'Blog',
    Icon: SpeakerPhoneIcon,
    href: '/blog',
  }, */
];
