import { ReactElement } from 'react';

interface Props {
  countNumber: number;
  text: string;
}

export function DentistCategoryItem({
  countNumber,
  text,
}: Props): ReactElement {
  return (
    <li className="flex flex-col py-0.5 px-[0.375rem]">
      <strong className="font-bold min-[1566px]:text-[2rem] text-[1.5rem] text-medium-emphasis">
        {countNumber}
      </strong>
      <span className="min-[1566px]:text-normal text-neutral-main">{text}</span>
    </li>
  );
}
