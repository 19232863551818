import { motion, AnimatePresence } from 'framer-motion';
import { ReactElement, useState } from 'react';

interface props {
  title: string;
  contentList: string[];
}

export default function ExpandableList({
  title,
  contentList,
}: props): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex-col">
      <button className="flex items-center" onClick={handleToggle}>
        <div className="flex items-center mt-4">
          <span className="inline-block bg-slate-500 text-white rounded-md px-1.5 items-center justify-center opacity-50 ">
            +
          </span>
          <span className="font-semibold text-slate-600 ml-5 text-lg">
            {title}
          </span>
        </div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.ul
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.3 }}
            style={{ originY: 0 }}
          >
            {contentList &&
              contentList.map((topic, index) => (
                <li
                  className="text-left w-72 my-2 text-gray-500 text-base font-base ml-4"
                  key={index}
                >
                  <strong className="font-bold text-lg">-</strong> {topic}
                </li>
              ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
}
