import { ReactElement } from 'react';

import BigBanner from '../../components/BigBanner';
import { Button } from '../../components/Button';
import { ArrowRightIcon } from '../../components/icons/ArrowRightIcon';
import { PageHelmet } from '../../components/PageHelmet';
import OuvidoriaForm from './OuvidoriaForm';

export default function OuvidoriaPage(): ReactElement {
  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="Ouvidoria | OdontoGroup" />

      <BigBanner
        href="#ouvidoria-form"
        title="Ouvidoria"
        page="ouvidoria"
        mainText="Para registrar sua mensagem na ouvidoria, é necessário um contato prévio com a nossa Central de Atendimento."
      />

      <section className="min-h-[300px] max-[748px]:block">
        <div className="min-[1000px]:flex items-center justify-between -mb-32 pl-20 pr-20 max-[1400px]:p-5">
          <div className="max-w-[762px]">
            <p className="font-[400] min-[1400px]:text-[1.5rem] leading-9 text-medium-emphasis">
              A Ouvidoria representa um canal de comunicação independente e
              imparcial, dedicado a ser a voz dos nossos clientes perante a
              nossa Operadora. Este espaço foi criado para receber seus
              comentários, elogios, sugestões e reclamações sobre nossos
              serviços e benefícios, visando a constante melhoria da qualidade
              do atendimento.
            </p>
            <br />
            <p className="font-[400] min-[1400px]:text-[1.5rem] leading-9 text-medium-emphasis">
              Para registrar sua mensagem na ouvidoria, é necessário um contato
              prévio com a nossa Central de Atendimento.
            </p>
            <br />
            <div className="bg-primary-container flex items-center justify-between rounded-xl p-5 max-[1400px]:flex-col gap-4 max-[748px]:z-3 max-[748px]:relative">
              <p className="text-primary-main text-[1.125rem] leading-7 font-[400]">
                Ainda não entrei em contato com o atendimento.
              </p>

              <a href="#ouvidoria-form">
                <Button
                  text="Ir para atendimento"
                  intent="primary"
                  Icon={ArrowRightIcon}
                  type="button"
                />
              </a>
            </div>
          </div>
          <img
            className="max-[748px]:hidden"
            src="/assets/images/ouvidoria.png"
            alt="Sorriso"
          />
        </div>
      </section>

      <section>
        <img
          className="hidden max-[748px]:block z-2 -mb-[15rem]"
          src="/assets/images/ouvidoria.png"
          alt="Sorriso"
        />
      </section>

      <section className="bg-primary-main min-h-[938px] justify-between max-[748px]:z-1"></section>

      <section className="max-[748px]:z-3 flex min-[748px]:gap-28 items-center min-[748px]:p-20 p-1 -mt-[50rem] max-[1400px]:flex-col max-[1400px]:bg-primary-main justify-center gap-3 pr-12 pl-12 max-[748px]:pr-5 max-[748px]:pl-5 max-[748px]:pb-24">
        <div className="bg-white max-h-[40%] rounded p-10 max-[748px]:p-5 min-[748px]:min-w-[676px] shadow-card">
          <h3 className="text-primary-main font-semibold text-[2rem] max-[1000px]:text-[1.125rem] max-w-[500px]">
            Registrar uma mensagem na ouvidoria
          </h3>
          <OuvidoriaForm />
        </div>

        <div>
          <p className="text-neutral-with text-2xl leading-9 font-[400] max-[748px]:text-base">
            Agora você nosso beneficiário, poderá acessar sua área destinada
            através do site ou baixar nosso aplicativo OdontoGroup Associado.
            Utilize seu CPF como login, defina sua senha e registre um CRM
            diretamente em nosso sistema de atendimento.{' '}
          </p>

          <p className="text-neutral-with text-2xl leading-9 font-bold mt-8 mb-5 max-[748px]:text-center max-[748px]:text-lg">
            Onde Baixar:
          </p>

          <div className="flex max-[748px]:flex-col gap-8 max-[748px]:items-center">
            <a
              className="max-w-[50%]"
              href={process.env.REACT_APP_GOOGLE_PLAY}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/images/google-play-tag.png"
                alt="Tag GooglePlay"
              />
            </a>
            <a
              className="max-w-[50%]"
              href={process.env.REACT_APP_APPLE_STORE}
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/images/app-store-tag.png" alt="Tag AppStore" />
            </a>
          </div>
        </div>
      </section>

      <section className="min-[748px]:min-h-[300px]" />
    </div>
  );
}
