import { ReactElement, ComponentProps } from 'react';

import clsx from 'clsx';

import { businessPlans, personalPlans, type Plan } from './data/plans';
import { NewPlanCard } from './NewPlanCard';

interface Props {
  className?: ComponentProps<'ul'>['className'];
  type?: 'individual' | 'business';
  customList?: Plan[];
}

export function NewPersonalPlans({
  className,
  type = 'individual',
  customList,
}: Props): ReactElement {
  const planList =
    customList ?? (type === 'individual' ? personalPlans : businessPlans);
  return (
    <div
      className={clsx(
        'mx-auto min-[1548px]:w-[1500px] min-[1700px]:w-[1700px] max-[1548px]:px-6',
        className,
      )}
    >
      <ul className="min-[1300px]:flex mt-[88px] grid grid-cols-4 max-[1300px]:grid-cols-2 max-[701px]:grid-cols-1 gap-3 list-none">
        {planList.map((plan, i) => (
          <NewPlanCard plan={plan} key={`${plan.name}+${i}`} type={type} />
        ))}
      </ul>
    </div>
  );
}
