import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import { motion, Variants } from 'framer-motion';

import { useWindowSizeStore } from '../../stores/useWindowSizeStore';
import { Button } from '../Button';
import { CaretDownIcon } from '../icons/CaretDownIcon';
import { CartIcon } from '../icons/CartIcon';
import { Select } from '../Select';

interface Props {
  plan: {
    type?: string;
    name: string;
    iconSource: string;
    covers: string[];
    coversPlus?: string[];
    category: Category[];
    shortage?: string;
  };
  type: string;
}

interface Category {
  type: string;
  price: string;
  token: string;
}

function getLiVariants(
  isThereShortage: string | undefined,
  width: number,
  type: string,
): Variants {
  if (type == 'individual') {
    if (width >= 1548) {
      return {
        open: { height: 930 },
        closed: { height: 490 },
      };
    }

    return {
      open: { height: isThereShortage ? 950 : 995 },
      closed: { height: 490 },
    };
  } else if (type == 'business') {
    if (width >= 1548) {
      return {
        open: { height: 805 },
        closed: { height: 475 },
      };
    }

    return {
      open: { height: isThereShortage ? 875 : 995 },
      closed: { height: 385 },
    };
  }
  if (width >= 1548) {
    return {
      open: { height: 805 },
      closed: { height: 475 },
    };
  }

  return {
    open: { height: isThereShortage ? 875 : 995 },
    closed: { height: 475 },
  };
}

function figureVariants(type): Variants {
  if (type == 'individual') {
    return {
      open: { height: 420, opacity: 100, marginTop: 24 },
      closed: { height: 0, opacity: 0, marginTop: -8 },
    };
  } else if (type == 'business') {
    return {
      open: { height: 420, opacity: 100, marginTop: 24 },
      closed: { height: 22, opacity: 0 },
    };
  }
  return {
    open: { height: 420, opacity: 100, marginTop: 24 },
    closed: { height: 0, opacity: 0, marginTop: -8 },
  };
}

const buttonVariants: Variants = {
  open: {
    y: '0',
  },
  closed: {
    y: -120,
  },
};

export function PlanCard({ plan, type }: Props): ReactElement {
  const width = useWindowSizeStore(state => state.width);
  const [isOpen, setIsOpen] = useState(false);
  const [categoryPlan, setCategoryPlan] = useState<string>();

  useEffect(() => {
    setCategoryPlan(plan.category[0].type);
  }, []);

  const handleCategoryPlanChange = (event: any) => {
    setCategoryPlan(event.target.value);
  };

  function toggleIsOpen() {
    setIsOpen(prevState => !prevState);
  }

  useEffect(() => {
    if (width >= 1300) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [width]);

  function getValueByCategory(): string {
    return (
      plan.category.find(cat => cat.type === categoryPlan)?.price ||
      plan.category[0].price
    );
  }

  function getCategoryTypes(): string[] {
    return plan.category.map(cat => cat.type);
  }

  function buttomDisable(): boolean {
    return !categoryPlan;
  }

  function linkToGetPlan() {
    const token =
      plan.category.find(cat => cat.type === categoryPlan)?.token || '';
    if (type == 'individual') {
      return `/contratar-plano/${token}`;
    } else return `empresa/contratar-plano/${token}`;
  }

  return (
    <motion.li
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      variants={getLiVariants(plan.shortage, width, type)}
      className="overflow-hidden flex flex-col justify-between w-[357px] min-[1548px]:h-[870px] bg-white shadow-card rounded-xl p-8 z-20 max-[1548px]:w-full"
    >
      <div>
        <div className="flex items-center gap-3 h-[89px] bg-[#F7F6FF] px-5 py-14 rounded-xl max-[1548px]:justify-center pb-14">
          <img src={plan.iconSource} alt="Vetor gráfico" />
          <div className="flex flex-col text-primary-main">
            <span className="text-xl">{plan.type ?? 'Plano'}</span>
            <strong className="text-2xl font-semibold">{plan.name}</strong>
          </div>
        </div>
        <motion.figure
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          variants={figureVariants(type)}
          className="flex flex-col gap-2 h-full"
        >
          <figcaption className="font-bold text-primary-main">
            Coberturas
          </figcaption>
          <ul className="flex flex-col text-medium-emphasis text-lg gap-2">
            {plan.covers.map(cover => (
              <li key={cover}>{cover}</li>
            ))}
            {plan.coversPlus &&
              plan.coversPlus.map(plus => (
                <li className="text-secondary-main font-medium" key={plus}>
                  {plus}
                </li>
              ))}
          </ul>
          {/* TODO quais carencias colocar */}
          {/* {plan.shortage && (
            <div className="flex flex-col text-medium-emphasis text-lg mt-4">
              <span className="font-bold text-primary-main">Carência</span>
              <span>{plan.shortage}</span>
            </div>
          )} */}
        </motion.figure>
      </div>

      {plan.category && plan.category.length > 1 && (
        <div className="py-6">
          <Select
            label="Categoria Plano"
            options={getCategoryTypes()}
            placeholder="Selecione a Categoria"
            value={categoryPlan}
            onChange={handleCategoryPlanChange}
          />
        </div>
      )}

      <div className="flex flex-col items-center gap-2 z-10 bg-white">
        <Link
          to={linkToGetPlan()}
          className="w-full flex"
          style={{ pointerEvents: buttomDisable() ? 'none' : 'auto' }}
        >
          <div className="w-full flex flex-col gap-3 justify-center items-center bg-[#F7F6FF] h-[136px] rounded-xl z-10">
            <span className="text-xl text-primary-main font-semibold">
              Valor mensal
            </span>
            <div className="flex flex-col items-center">
              <span className="text-sm text-medium-emphasis">A partir de</span>
              <span className="text-secondary-main">
                <strong className="font-bold">R$</strong>{' '}
                <strong className="text-[2rem]">{getValueByCategory()}</strong>
                por mês
              </span>
            </div>
          </div>
        </Link>
        <motion.div
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          variants={buttonVariants}
          className={clsx(
            !isOpen && 'gap-6',
            'flex flex-col min-[1548px]:w-[222px] max-[1548px]:max-w-[294px]',
          )}
        >
          <Link
            to={linkToGetPlan()}
            className="w-full flex"
            style={{ pointerEvents: buttomDisable() ? 'none' : 'auto' }}
          >
            <Button text="Contratar plano" Icon={CartIcon} className="w-full" />
          </Link>
          <Button
            onClick={toggleIsOpen}
            className={clsx(
              'min-[1548px]:hidden ',
              isOpen ? 'mt-4' : 'mt-[53px]',
            )}
            Icon={CaretDownIcon}
            iconClassName={clsx('stroke-primary-main', isOpen && 'rotate-180')}
            intent="tertiary"
            text={isOpen ? 'Ver menos informações' : 'Ver mais informações'}
          />
        </motion.div>
      </div>
    </motion.li>
  );
}
