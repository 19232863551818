import { ReactElement, ComponentProps } from 'react';
interface Props {
  className?: ComponentProps<'svg'>['className'];
}
export const CloudCheckIcon = ({ className }: Props): ReactElement => (
  <svg
    className={className}
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0017 18.9997L17.0017 22.9997L25.0017 14.9997M10.6717 4.3937C12.1063 4.2792 13.4682 3.7151 14.5637 2.7817C15.8015 1.72624 17.3749 1.14648 19.0017 1.14648C20.6284 1.14648 22.2018 1.72624 23.4397 2.7817C24.5351 3.7151 25.897 4.2792 27.3317 4.3937C28.9532 4.5227 30.4756 5.22535 31.6258 6.37555C32.776 7.52575 33.4787 9.0482 33.6077 10.6697C33.7222 12.1043 34.2863 13.4663 35.2197 14.5617C36.2751 15.7995 36.8549 17.373 36.8549 18.9997C36.8549 20.6264 36.2751 22.1999 35.2197 23.4377C34.2863 24.5331 33.7222 25.8951 33.6077 27.3297C33.4787 28.9512 32.776 30.4737 31.6258 31.6239C30.4756 32.7741 28.9532 33.4767 27.3317 33.6057C25.897 33.7202 24.5351 34.2843 23.4397 35.2177C22.2018 36.2732 20.6284 36.8529 19.0017 36.8529C17.3749 36.8529 15.8015 36.2732 14.5637 35.2177C13.4682 34.2843 12.1063 33.7202 10.6717 33.6057C9.05015 33.4767 7.5277 32.7741 6.3775 31.6239C5.2273 30.4737 4.52466 28.9512 4.39566 27.3297C4.28115 25.8951 3.71706 24.5331 2.78366 23.4377C1.72819 22.1999 1.14844 20.6264 1.14844 18.9997C1.14844 17.373 1.72819 15.7995 2.78366 14.5617C3.71706 13.4663 4.28115 12.1043 4.39566 10.6697C4.52466 9.0482 5.2273 7.52575 6.3775 6.37555C7.5277 5.22535 9.05015 4.5227 10.6717 4.3937Z"
      stroke="#E6007E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
