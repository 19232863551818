import { ReactElement } from 'react';

import { CloudCheckIcon } from './icons/CloudCheckIcon';

interface Props {
  title?: string;
  text: string;
  extras?: ReactElement;
}

const InfoCard = ({ title, text, extras }: Props): ReactElement => (
  <div className="shadow-card p-6 rounded-lg bg-white">
    <div className="flex items-center gap-4">
      <CloudCheckIcon className="min-w-[38px] min-h-[38px] max-[999px]:self-start max-[999px]:min-w-[24px] max-[999px]:min-h-[24px]" />
      <div>
        {title ? (
          <h3 className="text-primary-main font-bold text-2xl max-[999px]:text-lg">
            {title}
          </h3>
        ) : null}
        <p className="text-2xl text-medium-emphasis max-[999px]:text-lg">
          {text}
        </p>
      </div>
    </div>
    {extras}
  </div>
);

export default InfoCard;
