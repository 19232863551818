import { FC } from 'react';

import { BilletIcon } from '../../../../icons/BilletIcon';
import { CreditCardIcon } from '../../../../icons/CreditCardIcon';
import { DirectDebitIcon } from '../../../../icons/DirectDebitIcon';
import { PayrollDeductionIcon } from '../../../../icons/PayrollDeductionIcon';
import { UniqueBilletIcon } from '../../../../icons/UniqueBilletIcon';

interface PaymentOption {
  id: number;
  label: string;
  value: string;
  Icon: FC;
}

export const paymentOptions: PaymentOption[] = [
  {
    id: 1,
    label: 'Cartão de crédito',
    value: 'credit-card',
    Icon: CreditCardIcon,
  },
  {
    id: 3,
    label: 'Boleto bancário',
    value: 'unique-billet',
    Icon: UniqueBilletIcon,
  },
  {
    id: 2,
    label: 'Débito em conta',
    value: 'direct-debit',
    Icon: DirectDebitIcon,
  },
  /*   {
    id: 4,
    label: 'Desconto em folha',
    value: 'payroll-deduction',
    Icon: PayrollDeductionIcon,
  }, */
];
