import { ReactElement, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

interface Props {
  slideOpen: boolean;
  setIsSideOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

export function SideBar({
  slideOpen,
  setIsSideOpen,
  children,
}: Props): ReactElement {
  const controls = useAnimation();

  useEffect(() => {
    if (slideOpen) {
      openSidenav();
    } else {
      closeSidenav();
    }
  }, [slideOpen]);

  const openSidenav = async () => {
    setIsSideOpen(true);
    await controls.start({ x: '100%', transition: { duration: 0 } });
    controls.start({ x: '0%', transition: { duration: 0.4 } });
  };

  const closeSidenav = async () => {
    controls.start({ x: '100%', transition: { duration: 0.4 } }).then(() => {
      setIsSideOpen(false);
    });
  };

  const handleBackdropClick = event => {
    if (event.target.classList.contains('backdrop')) {
      closeSidenav();
    }
  };

  return (
    <>
      {slideOpen && (
        <>
          <motion.div
            className="backdrop fixed top-0 left-0 w-full h-full bg-black opacity-0 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.6 }}
            onClick={handleBackdropClick}
          ></motion.div>

          <motion.div
            className="fixed top-0 right-0 w-[400px] h-full bg-white z-50 overflow-y-auto"
            initial={{ x: '100%' }}
            animate={controls}
          >
            <div className="flex flex-col w-full  text-center ml-14 mt-8">
              {children}
            </div>
          </motion.div>
        </>
      )}
    </>
  );
}
