import { ReactElement } from 'react';

import { BenefitCard } from './BenefitCard';

export function BenefitsSection(): ReactElement {
  return (
    <section className="min-[1548px]:h-[815px] bg-benefits bg-no-repeat bg-cover relative flex items-end justify-center">
      <div className="min-[1548px]:w-[995px] w-full max-[1548px]:px-6 mx-auto flex flex-col justify-center text-center self-center">
        <div className="min-[1548px]:mb-[122px] my-12">
          <h2 className="min-[1548px]:text-[2.625rem] min-[701px]:text-[2rem] text-lg text-white font-semibold mb-3">
            Aqui você leva muito mais do que um plano!
          </h2>
          <p className="min-[1548px]:text-2xl min-[701px]:text-xl text-base text-white font-normal">
            Conheças os benefícios que você vai adquirir junto com o seu plano.
          </p>
          <ul className="flex justify-center gap-6 mt-12 max-[701px]:flex-col">
            <BenefitCard
              percent={25}
              text="em medicamentos nas redes Drogasil e Droga Raia"
            />
            <BenefitCard
              percent={30}
              text="de desconto em exames e vacinas no laboratório Sabin"
            />
            <BenefitCard percent={30} text="de desconto no laboratório Exame" />
          </ul>
        </div>
      </div>
    </section>
  );
}
