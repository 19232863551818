import { ReactElement } from 'react';

import { BusinessPlans } from './BusinessPlans';
import { PersonalPlans } from './PersonalPlans';

export function PlansSection(): ReactElement {
  return (
    <>
      <PersonalPlans />
      <BusinessPlans />
    </>
  );
}
