import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { useWindowSizeStore } from '../../stores/useWindowSizeStore';
import { Button } from '../Button';
import { NetworkMap } from './NetworkMap';

export function NetworkSection(): ReactElement {
  const width = useWindowSizeStore(state => state.width);

  return (
    <section className="bg-primary-main min-[1607px]:h-[906px] max-[1606px]:py-20">
      <div className="relative min-[1606px]:w-[1558px] max-[1606px]:px-6 mx-auto flex flex-col justify-center h-full">
        <div className="flex  max-[1606px]:justify-center max-[1018px]:flex-col max-[1018px]:items-center">
          {width < 1018 && <NetworkMap />}
          <div className="flex flex-col max-w-[463px]">
            <div className="flex items-center gap-3 mb-[88px] relative">
              <div className="flex items-center gap-1 max-[1018px]:flex-col w-full max-[1018px]:mt-10">
                <strong className="font-bold text-secondary-main min-[1019px]:text-7xl text-6xl">
                  +32.000
                </strong>
                <div className="min-[1019px]:text-[2.375rem] text-[2rem] flex min-[1019px]:flex-col leading-[34px] text-neutral-200">
                  <span className="max-[1018px]:mr-2">Dentistas</span>
                  <span className="font-bold"> parceiros</span>
                </div>
              </div>
              <img
                src="/assets/images/sorriso.png"
                alt="Sorriso"
                className="absolute -right-24 -bottom-24 max-[1606px]:hidden"
              />
            </div>
            <h3 className="text-neutral-with font-semibold min-[1606px]:text-[2.625rem] min-[1019px]:text-3xl max-[1019px]:text-center text-lg leading-10  mb-8">
              Conheça nossa rede de atendimento
            </h3>
            <p className="text-neutral-with min-[1606px]:text-2xl min-[1019px]:text-xl mb-12 max-[1018px]:text-center w-full">
              Conte com os melhores especialistas que podem lhe atender em
              qualquer estado.
            </p>
            <div className="max-[1018px]:self-center">
              <Button
                text={
                  <Link
                    to={process.env.REACT_APP_REDE_CREDENCIADA ?? '/'}
                    target="_blank"
                  >
                    Ver redes credenciadas
                  </Link>
                }
                intent="quaternary"
              />
            </div>
          </div>
          <img
            src="/assets/images/network/avatar.png"
            alt="Avatar OdontoGroup"
            className="min-[1606px]:h-[689px] min-[1606px]:w-[444px] h-[389px] absolute bottom-0 left-[483px] max-[1606px]:hidden"
          />
          {width >= 1018 && <NetworkMap />}
        </div>
      </div>
    </section>
  );
}
