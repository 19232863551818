import { ButtonHTMLAttributes, FC, ReactElement } from 'react';
import { cva, VariantProps } from 'class-variance-authority';

const styles = cva(
  'flex items-center gap-4 px-6 py-4 text-medium-emphasis font-bold border-b border-neutral-lighter max-[701px]:py-4 max-[701px]:px-2 max-[701px]:text-sm',
  {
    variants: {
      active: {
        true: 'border-secondary-main border-b-4',
        false: 'pb-[1.188rem]',
      },
    },
  },
);

interface Props
  extends VariantProps<typeof styles>,
    ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  Icon: FC<{ className?: string }>;
}

export function PlanSelector({
  text,
  Icon,
  active = false,
  ...props
}: Props): ReactElement {
  return (
    <button
      className={styles({ active })}
      style={{ cursor: 'pointer' }} // Add cursor style here
      {...props}
    >
      <Icon
        className={active ? 'stroke-secondary-main' : 'stroke-neutral-main'}
      />
      <span>{text}</span>
    </button>
  );
}
