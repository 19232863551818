import { ReactElement } from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

export function BriefCaseIcon({
  width = '13',
  height = '14',
  className,
}: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 13 14"
    >
      <path
        className={className}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.5 8.837a15.954 15.954 0 01-6 1.164c-2.122 0-4.147-.414-6-1.164m6-.836h.007m2.66-4V2.667a1.333 1.333 0 00-1.334-1.333H5.167a1.333 1.333 0 00-1.334 1.333v1.334h5.334zm-7.334 9.333h9.334a1.334 1.334 0 001.333-1.333V5.334a1.333 1.333 0 00-1.333-1.333H1.833A1.333 1.333 0 00.5 5.334v6.667a1.333 1.333 0 001.333 1.333z"
      ></path>
    </svg>
  );
}
