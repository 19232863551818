import { ReactElement, useEffect, useState } from 'react';

import clsx from 'clsx';
import { motion, Variants } from 'framer-motion';

import { useWindowSizeStore } from '../../../stores/useWindowSizeStore';
import { CaretDownIcon } from '../../icons/CaretDownIcon';
import { ContentHandlerPurchaseSummary } from './ContentHandlerPurchaseSummary';

export function PurchaseSummary(): ReactElement {
  const width = useWindowSizeStore(state => state.width);
  const [isOpen, setIsOpen] = useState(width >= 748 ? true : false);

  const variants: Variants = {
    open: { height: width >= 748 ? 340 : 400 },
    closed: { height: 180 },
  };

  function toggleIsOpen() {
    setIsOpen(prevState => !prevState);
  }

  useEffect(() => {
    if (width >= 748) {
      setIsOpen(true);
    }
  }, [width]);

  return (
    <div className="h-full flex flex-col gap-8 mx-auto max-[748px]:w-full w-[50%]">
      <motion.section
        variants={variants}
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        className="min-[748px]:max-w-[453px] max-[748px]:w-full bg-white rounded-xl shadow-card p-6 flex flex-col justify-between"
      >
        <ContentHandlerPurchaseSummary isPurchaseSummaryOpen={isOpen} />
        {width < 748 && (
          <div className="flex justify-center mt-8">
            <button className="flex gap-1 p-1" onClick={toggleIsOpen}>
              <CaretDownIcon
                className={clsx('stroke-neutral-700', isOpen && 'rotate-180')}
              />
              <span className="text-primary-main">
                {isOpen ? 'Mostrar resumo' : 'Ver detalhes'}
              </span>
            </button>
          </div>
        )}
      </motion.section>
    </div>
  );
}
