import * as z from 'zod';

import { schema } from './';

export const debitSchema = z.object({
  payment: z.optional(
    z.object({
      firstPaymentBillet: z.optional(z.boolean()),
      bank: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      operation: z.optional(
        z.string().min(1, { message: 'Campo obrigatório' }),
      ),
      billetDate: z.optional(
        z.string().min(1, { message: 'Campo obrigatório' }),
      ),
      debitDate: z.optional(
        z.string().min(1, { message: 'Campo obrigatório' }),
      ),
      agency: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
      debitAcount: z.optional(
        z.string().min(1, { message: 'Campo obrigatório' }),
      ),
    }),
  ),

  user: schema,
});

export type DebitSchema = z.infer<typeof debitSchema>;
