import { create } from 'zustand';

type DependentState = {
  dependentCount: number;
  addDependent: () => void;
  removeDependent: () => void;
};

export const useDependentCountStore = create<DependentState>(set => ({
  dependentCount: 0,
  addDependent: () =>
    set(state => ({ dependentCount: state.dependentCount + 1 })),
  removeDependent: () =>
    set(state => ({ dependentCount: state.dependentCount - 1 })),
}));
