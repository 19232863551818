import { create } from 'zustand';

type NortheastPlan = {
  data: any;
  setNortheastPlanData: (data: object) => void;
};

export const useNortheastPlanDataStore = create<NortheastPlan>((set, get) => ({
  data: {},
  setNortheastPlanData: data => set(() => ({ data: data })),
  action: () => {
    const data = get().data;
    // ...
  },
}));
