import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export function MobileAppSection(): ReactElement {
  return (
    <section className="min-[1412px]:h-[544px] relative max-[1412px]:pt-20 pb-[148px] min-[1412px]:bg-app-section-gradient-horizontal bg-app-section-gradient-vertical bg-cover">
      <div className="w-full h-full bg-mobile-app-background bg-no-repeat bg-cover absolute top-0 -z-10" />
      <div className="min-[1412px]:w-[1364px] h-full mx-auto flex min-[1412px]:justify-end justify-center items-center">
        <div className="min-[701px]:w-[491px] min-[1412px]:h-[412px] flex flex-col items-center">
          <img
            src="/assets/images/sorriso.png"
            alt=""
            className="rotate-[20.71deg] max-[1412px]:hidden"
          />
          <h3 className="min-[1412px]:text-[2.625rem] min-[701px]:text-[2.25rem] text-lg text-neutral-with font-semibold mb-10 max-[701px]:text-center">
            Na palma da sua mão!
          </h3>
          <p className="min-[1412px]:text-2xl min-[701px]:text-xl text-base max-[701px]:max-w-[342px] text-neutral-with text-center">
            Faça a gestão do seu plano pelo app. Encontre seu dentista e acesse
            sua carteirinha 100% digital.
          </p>
          <div className="flex gap-7 mt-11 max-[702px]:flex-col">
            <Link
              to="https://play.google.com/store/apps/details?id=com.odontogroup.associado"
              target="_blank"
            >
              <img
                src="/assets/images/google-play-tag.png"
                alt="Tag GooglePlay"
              />
            </Link>
            <Link
              to="https://apps.apple.com/br/app/odontogroup-associado/id1437832550"
              target="_blank"
            >
              <img src="/assets/images/app-store-tag.png" alt="Tag AppStore" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
