export const getPlanDetails = async (
  token: string | undefined,
): Promise<object> => {
  const data = await fetch(
    `${process.env.REACT_APP_OG_GET_PLAN_DETAILS_INDIVIDUAL}${token}`,
  ).then(response => {
    return response.json();
  });

  return data;
};
