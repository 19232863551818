import { ReactElement } from 'react';

import { clsx } from 'clsx';

import { useWindowSizeStore } from '../../stores/useWindowSizeStore';
import { Button } from '../Button';
import { LeadingIcon } from '../icons/LeadingIcon';
import { HashLink } from 'react-router-hash-link';
interface Props {
  text: ReactElement;
  link: string | undefined;
  textButton: string;
  alt: string;
  justify?: 'center' | 'between';
  imgSource: string;
}

export function SuspendedCardItem({
  text,
  link,
  textButton,
  alt,
  justify = 'between',
  imgSource,
}: Props): ReactElement {
  const width = useWindowSizeStore(state => state.width);

  return (
    <li className="h-full flex min-[1302px]:flex-row justify-center flex-col items-center min-[1302px]:gap-10 gap-4 min-[1302px]:py-9 min-[1302px]:px-6 p-2 min-[1302px]:h-[204px] bg-white shadow-[0px_1px_7px_rgba(58,39,179,0.16)] rounded-xl">
      <img
        src={imgSource}
        alt={alt}
        className="object-scale-down max-w-[64px] max-h-[64px] sm:max-w-[130px] sm:max-h-[130px]"
      />
      <div
        className={clsx(
          'flex flex-col h-full min-[1302px]:items-start items-center max-[1302px]:w-full',
          justify === 'between' && 'justify-between',
          justify === 'center' && 'justify-center gap-8',
        )}
      >
        <span className="px-2 font-normal text-medium-emphasis min-[1460px]:text-2xl min-[1302px]:text-lg min sm:text-lg text-xs min-[1302px]:text-start text-center">
          {text}
        </span>
        <div className="min-[1302px]:mt-0 mt-4 max-[1302px]:w-full">
          <HashLink to={link}>
            <Button
              text={<a href={link}>{textButton}</a>}
              intent={width >= 1302 ? 'secondary' : 'tertiary'}
              Icon={width >= 1302 ? LeadingIcon : undefined}
              className="sm:text-sm text-xs max-[1302px]:px-0 max-[1302px]:w-full max-[1302px]flex max-[1302px]:justify-center"
            />
          </HashLink>
        </div>
      </div>
    </li>
  );
}
