import { ReactElement } from 'react';

import { useHirePlanForm } from '../../../../contexts/HirePlanFormProvider';
import { useDependentCountStore } from '../../../../stores/useDependentCountStore';
import { Button } from '../../../Button';
import { CloudIcon } from '../../../icons/CloudIcon';
import { SaveIcon } from '../../../icons/SaveIcon';
import { Input } from '../../../Input';
import { Select } from '../../../Select';
import { useRouteLoaderData } from 'react-router-dom';
import {
  changeCivilStatus,
  changeGender,
  changeIssuingAgency,
  changeParentage,
  changeUF,
} from '../../../../utils/ChangeArrays';

interface Props {
  onSave: () => void;
}

export function DependentForm({ onSave }: Props): ReactElement {
  const { register, handleSubmit, errors, setValue } = useHirePlanForm();
  const data: any = useRouteLoaderData('token');

  const dependentCount = useDependentCountStore(state => state.dependentCount);
  const addDependent = useDependentCountStore(state => state.addDependent);

  function onSubmit() {
    setValue(`user.dependents.${dependentCount + 1}.name`, '');
    setValue(`user.dependents.${dependentCount + 1}.birthDate`, '');
    setValue(`user.dependents.${dependentCount + 1}.civilStatus`, '');
    setValue(`user.dependents.${dependentCount + 1}.gender`, '');
    setValue(`user.dependents.${dependentCount + 1}.parentage`, '');
    setValue(`user.dependents.${dependentCount + 1}.cpf`, '');
    setValue(`user.dependents.${dependentCount + 1}.rg`, '');
    setValue(`user.dependents.${dependentCount + 1}.issuingAgency`, '');
    setValue(`user.dependents.${dependentCount + 1}.issuingAgencyUF`, '');
    setValue(`user.dependents.${dependentCount + 1}.cns`, '');
    setValue(`user.dependents.${dependentCount + 1}.mothersName`, '');

    addDependent();
    onSave();
  }

  changeUF(data.listaUFS);
  changeGender(data.listaSexos);
  changeIssuingAgency(data.listaOrgaosExpedidor);
  changeCivilStatus(data.listaEstadosCivil);
  changeParentage(data.listaParentescos);

  return (
    <form
      className="border border-[#DFE2F2] w-full min-[748px]:mt-16 rounded-xl p-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col gap-8">
        <div className="grid min-[748px]:grid-cols-3 gap-8">
          <div className="min-[748px]:col-span-2">
            <Input
              label="Nome"
              placeholder="Nome"
              errorMessage={
                errors?.user?.dependents?.[dependentCount]?.name?.message
              }
              {...register(`user.dependents.${dependentCount}.name`)}
            />
          </div>
          <Input
            activateMask
            mask="99/99/9999"
            label="Data de nascimento"
            placeholder="dd/mm/aaaa"
            errorMessage={
              errors?.user?.dependents?.[dependentCount]?.birthDate?.message
            }
            {...register(`user.dependents.${dependentCount}.birthDate`)}
          />
        </div>
        <div className="grid min-[748px]:grid-cols-3 gap-8">
          <Select
            label="Estado civil"
            placeholder="Estado civil"
            options={[]}
            optionsFull={data.listaEstadosCivil}
            errorMessage={
              errors?.user?.dependents?.[dependentCount]?.civilStatus?.message
            }
            {...register(`user.dependents.${dependentCount}.civilStatus`)}
          />
          <Select
            label="Gênero"
            placeholder="Gênero"
            options={[]}
            optionsFull={data.listaSexos}
            errorMessage={
              errors?.user?.dependents?.[dependentCount]?.gender?.message
            }
            {...register(`user.dependents.${dependentCount}.gender`)}
          />
          <Select
            label="Parentesco"
            placeholder="Parentesco"
            options={[]}
            optionsFull={data.listaParentescos}
            errorMessage={
              errors?.user?.dependents?.[dependentCount]?.parentage?.message
            }
            {...register(`user.dependents.${dependentCount}.parentage`)}
          />
        </div>
        <div className="grid min-[748px]:grid-cols-2 gap-8">
          <Input
            activateMask
            mask="999.999.999-99"
            label="CPF"
            placeholder="CPF"
            errorMessage={
              errors?.user?.dependents?.[dependentCount]?.cpf?.message
            }
            {...register(`user.dependents.${dependentCount}.cpf`)}
          />
          <Input
            label="RG"
            placeholder="RG"
            //errorMessage={errors?.dependents?.[dependentCount]?.rg?.message}
            {...register(`user.dependents.${dependentCount}.rg`)}
          />
        </div>
        <div className="grid min-[748px]:grid-cols-3 gap-8">
          <div className="grid min-[748px]:grid-cols-2 col-span-2 max-[748px]:grid-cols-1 gap-8">
            <Select
              label="Órgão expeditor"
              placeholder="Órgão"
              options={[]}
              optionsFull={data.listaOrgaosExpedidor}
              errorMessage={
                errors?.user?.dependents?.[dependentCount]?.issuingAgency
                  ?.message
              }
              {...register(`user.dependents.${dependentCount}.issuingAgency`)}
            />
            <Select
              label="UF do órgão expeditor"
              placeholder="UF do órgão"
              options={[]}
              optionsFull={data.listaUFS}
              errorMessage={
                errors?.user?.dependents?.[dependentCount]?.issuingAgencyUF
                  ?.message
              }
              {...register(`user.dependents.${dependentCount}.issuingAgencyUF`)}
            />
          </div>
          <div className="max-[748px]:col-span-2">
            <Input
              label="CNS"
              placeholder="CNS"
              //errorMessage={errors?.dependents?.[dependentCount]?.cns?.message}
              {...register(`user.dependents.${dependentCount}.cns`)}
            />
          </div>
        </div>
        <div className="grid grid-cols-1">
          <Input
            label="Nome da mãe"
            placeholder="Nome da mãe"
            errorMessage={
              errors?.user?.dependents?.[dependentCount]?.mothersName?.message
            }
            {...register(`user.dependents.${dependentCount}.mothersName`)}
          />
        </div>
      </div>
      {/* <div className="px-3">
        <h3 className="text-high-emphasis text-xl text-center py-8">
          Comprovante de vinculo do dependente
        </h3>
        <label
          htmlFor="file"
          className="cursor-pointer h-[176px] w-full bg-[#F9FAFA] rounded-lg shadow-md p-6 flex flex-col items-center justify-between"
        >
          <CloudIcon />
          <div>
            <span className="block text-neutral-600">
              Arraste seu arquivo para cá ou
            </span>
            <div className="flex justify-center">
              <span className="text-[#0065BD]">Selecione um arquivo</span>
            </div>
          </div>
        </label>
        <input
          id="file"
          type="file"
          name="file"
          className="appearance-none w-[0.1px] h-[0.1px] opacity-0 overflow-hidden absolute z-[-1]"
        />
      </div> */}
      <div className="flex justify-center mt-8">
        <Button text="Salvar dependente" intent="secondary" Icon={SaveIcon} />
      </div>
    </form>
  );
}
