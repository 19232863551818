import { ReactElement } from 'react';

import BigBanner from '../../components/BigBanner';
import DoubleCard from '../../components/DoubleCard';
import InfoCard from '../../components/InfoCard';
import { PageHelmet } from '../../components/PageHelmet';
import DentistForm from './DentistForm';

export default function DentistPage(): ReactElement {
  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="Dentista | OdontoGroup" />

      <BigBanner title="Dentista" page="dentist" hideBtn hideText />
      <section>
        <DoubleCard
          className="mx-auto -mt-24"
          title1="Já sou Dentista OdontoGroup"
          text1="Acessar portal"
          title2="Ainda não sou dentista da OdontoGroup"
          text2="Quero me credenciar"
          icon1="dentist"
          icon2="team"
          link2="#dentist-form"
          blank1
          useAnchor2
        />
      </section>

      <section className="min-h-[300px] max-[748px]:block min-[1000px]:text-center my-20 min-[1000px]:px-56 max-[999px]:px-[15%]">
        <h1 className="text-[2.625rem] text-primary-main font-semibold max-[999px]:text-2xl">
          Amplie sua rede de atuação com o total apoio da OdontoGroup. Conte com
          a nossa <span className="text-secondary-main">experiência</span> e{' '}
          <span className="text-secondary-main">suporte</span>.
        </h1>
      </section>

      <section className="flex flex-col gap-10 my-10 min-[1000px]:px-32 max-[999px]:px-10">
        <InfoCard
          title="Aumento da carteira de clientes"
          text="Ao se credenciar na OdontoGroup, você terá acesso a uma ampla rede de pacientes que buscam serviços odontológicos de qualidade. Com a credibilidade e o alcance da OdontoGroup, você poderá expandir sua base de clientes e aumentar suas oportunidades de negócios."
        />

        <InfoCard
          title="Central de Atendimento especializada e suporte abrangente"
          text="Nossa equipe de credenciamento é altamente capacitada e dedicada a oferecer suporte especializado. Estamos disponíveis para auxiliar em todas as etapas do processo, garantindouma experiência tranquila e satisfatória para todos."
        />

        <InfoCard
          title="Tecnologia"
          text="Através do nosso portal, você pode realizar a aprovação de procedimentos, entrar em contato conosco pelo CRM do prestador, ter acesso a todos os procedimentos feito pelo plano e muito mais."
        />
      </section>

      <section className="min-h-[300px] max-[748px]:block min-[1000px]:text-center my-20 min-[1000px]:px-56 max-[999px]:px-[15%]">
        <h1 className="text-[2.625rem] text-primary-main font-semibold max-[999px]:text-2xl">
          Vamos transformar sorrisos enquanto crescermos juntos. Se credencie
          agora e seja um Dentista parceiro!
        </h1>
      </section>

      <section className="bg-[url('/public/assets/images/dentists/dentist.png')] bg-contain bg-no-repeat min-[1059px]:min-h-[900px] p-3 min-[1059px]:p-10 max-[1059px]:mb-24">
        <div className="bg-white max-h-[40%] rounded p-10 max-[748px]:p-5 min-[748px]:min-w-[676px] shadow-card min-[1059px]:max-w-[30%] mt-24">
          <DentistForm />
        </div>
      </section>

      <section className="flex items-center max-[1199px]:flex-col gap-5">
        <img src="/assets/images/phones.png" />

        <div className="max-[1199px]:text-center max-[1199px]:flex max-[1199px]:flex-col max-[1199px]:items-center max-[700px]:mx-4">
          <img
            src="/assets/images/sorriso2.png"
            className="max-h-[67px] max-w-[178px]"
          />
          <h1 className="font-semibold text-[2.625rem] leading-[3.938rem] text-primary-main max-w-[625px] my-4 max-[700px]:text-lg">
            Baixe agora o App OdontoGroup Prestador
          </h1>

          <p className="text-medium-emphasis text-2xl max-w-[472px] max-[700px]:text-base">
            Faça abertura de chamados, acesso a documentação do paciente,
            produtividade e muito mais!
          </p>

          <p className="text-medium-emphasis text-[1.25rem] font-normal my-3">
            Onde Baixar:
          </p>
          <div className="flex max-[748px]:flex-col gap-6 max-[748px]:items-center">
            <a
              className="max-w-[50%]"
              href={process.env.REACT_APP_GOOGLE_PLAY}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/images/google-play-tag.png"
                alt="Tag GooglePlay"
              />
            </a>
            <a
              className="max-w-[50%]"
              href={process.env.REACT_APP_APPLE_STORE}
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/images/app-store-tag.png" alt="Tag AppStore" />
            </a>
          </div>
        </div>
      </section>

      <section className="">
        <h1 className="mx-auto text-center max-w-[10rem] mt-28 mb-10 min-[1200px]:hidden text-primary-main font-semibold text-lg">
          Primeiro Acesso?
        </h1>
        <div className="flex items-center justify-between gap-5 mx-28 max-[999px]:mx-5 max-[999px]:gap-0 max-[999px]:mb-10">
          <div className="max-w-[730px] max-[1199px]:flex max-[1199px]:flex-col max-[1199px]:items-center max-[700px]:mx-4">
            <h1 className="font-semibold text-[2.625rem] leading-[3.938rem] text-primary-main max-w-[625px] my-4 max-[700px]:text-lg max-[1199px]:hidden">
              Primeiro acesso?
            </h1>

            <p className="text-medium-emphasis text-2xl mb-10 max-[999px]:text-base">
              <span className="font-extrabold text-[1.25rem] text-high-emphasis max-[999px]:text-[1rem]">
                Acesso Dentista:
              </span>
              <br /> O login é o seu cpf e a senha os 3 primeiros dígitos do CPF
              mais o seu nº do CRO. Após o primeiro login, é necessário
              cadastrar uma nova senha contendo de 8 a 10 números.
            </p>

            <p className="text-medium-emphasis text-2xl max-[999px]:text-base">
              <span className="font-extrabold text-[1.25rem] text-high-emphasis max-[999px]:text-[1rem]">
                Acesso Clínica:
              </span>
              <br /> O login é o CNPJ da Clínica e a senha os 6 primeiros
              números do CNPJ. Após o primeiro login, é necessário cadastrar uma
              nova senha contendo de 8 a 10 números.
            </p>
          </div>
          <img
            src="/assets/images/sol/half-sol.png"
            className="max-[1199px]:hidden"
          />
          <img
            src="/assets/images/sol/full-sol.png"
            className="min-[1200px]:hidden"
          />
        </div>
      </section>
    </div>
  );
}
