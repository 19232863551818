import { ReactElement } from 'react';

import BigBanner from '../../components/BigBanner';
import DoubleCard from '../../components/DoubleCard';
import InfoCard from '../../components/InfoCard';
import { PageHelmet } from '../../components/PageHelmet';
import QuickAccess from '../../components/QuickAccess';
import SolTextBox from '../../components/SolTextBox';
import CompanyForm from './CompanyForm';
import ContractSection from './ContractSection';

export default function CompaniesPage(): ReactElement {
  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="Empresas | OdontoGroup" />

      <BigBanner title="Empresas" page="companies" hideBtn hideText />
      <section>
        <DoubleCard
          className="mx-auto -mt-24"
          title1="Já Sou Cliente"
          text1="Acessar Portal Empresarial"
          title2="Ainda não sou Cliente"
          text2="Quero contratar"
          link1="https://odontogroup.s4e.com.br/sys/?TipoUsuario=2"
          link2="#company-contracts"
          useAnchor1
          useAnchor2
          blank1
        />
      </section>

      <QuickAccess className="mt-20 mb-32" />

      <section className="flex flex-col gap-5 my-10 min-[1000px]:px-32 max-[999px]:px-10">
        <SolTextBox
          title="Encontre o melhor plano odontológico para a sua empresa"
          text="Nosso objetivo é proporcionar coberturas abrangentes que atendam às reais necessidades dos seus colaboradores, oferecendo um atendimento personalizado e de alto padrão."
          maxTextSize={1042}
        />

        <InfoCard text="Planos diferenciados, adaptados ao perfil e às necessidades individuais da sua empresa e com um modelo de custeio personalizado." />
        <InfoCard text="Serviço de Concierge para auxiliar os funcionários a marcar consultas e na escolha de tratamentos e especialidades, proporcionando facilidade e comodidade." />
        <InfoCard text="Com a nossa rede de descontos, os beneficiários têm desconto em medicamentos, exames laboratoriais, vacinas e check-ups." />
        <InfoCard text="Nosso Odontomóvel oferece atendimento odontológico promovendo a saúde bucalcom atendimento personalizado aos pacientes." />
        <InfoCard text="Plano odontológico é o segundo benefício mais prevalente no país. Pesquisas indicam que 91,7% das empresas brasileiras oferecem planos odontológicos aos funcionários." />
        <InfoCard text="Faça a emissão de relação de beneficiários, inclusões e exclusões (movimentações), totalmente on-line." />
        <InfoCard text="Nossa Equipe de Relacionamento está sempre presente no dia-a-dia da sua empresa, oferecendo um atendimento diferenciado ao RH." />
        <InfoCard text="Participação em eventos como SIPAT e Institucionais para a conscientização do cuidado com a saúde bucal." />
      </section>

      <ContractSection />

      <section className="bg-primary-main min-[1299px]:hidden min-h-[976px] mb-[25rem]" />

      <section className="bg-primary-main min-h-[1253px] max-[999px]:block mt-20 flex justify-center px-5 max-[999px]:px-8 max-[999px]:min-h-[900px]">
        <div className="mt-auto flex justify-center max-[1199px]:flex-col max-[1199px]:items-center max-[999px]:-mb-52">
          <h2 className="min-[1548px]:text-[2.625rem] min-[1548px]:leading-[3.938rem] min-[701px]:text-[2rem] text-lg text-white font-semibold mb-3 max-w-[610px] self-center max-[1199px]:mb-20 max-[1199px]:mt-60">
            Entre em contato para conhecer planos para mais de 200 vidas
          </h2>
          <img
            src="/assets/images/sol/half-sol.png"
            className="max-[1199px]:hidden w-[470px] h-[640px] self-end"
          />
          <div className="bg-white rounded p-10 max-[748px]:p-5 min-[1000px]:min-w-[406px] max-[999px]:w-full shadow-card min-[1059px]:max-w-[30%] mb-10">
            <CompanyForm />
          </div>
        </div>
      </section>

      <section className="bg-white min-[1000px]:hidden min-h-[300px]" />
    </div>
  );
}
