import { ReactElement, useEffect, useState } from 'react';

import { useHirePlanForm } from '../../../../contexts/HirePlanFormProvider';
import { useWindowSizeStore } from '../../../../stores/useWindowSizeStore';
import { CartIcon } from '../../../icons/CartIcon';
import Loading from '../../../icons/Loading';
import { GoForwardButton } from '../../GoForwardButton';
import { PaymentSelector } from './PaymentSelector';
import { FinancialResponsibleForm } from '../FinancialResponsibleForm';
import { Button } from '../../../Button';
import { useLoadingStore } from '../../../../stores/useLoadingStore';
import { ErrorModal } from '../../../Modal/Modal';
import { useError } from '../../../../stores/useError';

export function PaymentMethodForm(): ReactElement {
  const { handleSubmit, onSubmit } = useHirePlanForm();

  const width = useWindowSizeStore(state => state.width);

  const loading = useLoadingStore(state => state.loading);
  const error = useError(state => state.error);
  const title = useError(state => state.title);
  const text = useError(state => state.text);

  return (
    <form
      className="flex flex-col items-center max-w-[755px] w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      {error && <ErrorModal open={true} title={title} text={text} />}
      <FinancialResponsibleForm />
      {width >= 748 && (
        <>
          <h2 className="text-[2rem] font-semibold text-primary-main mt-12">
            Forma de pagamento
          </h2>
          <p className="font-normal text-secondary-main mt-1 text-center w-11/12">
            Escolha uma forma de pagamento. Um link será enviado para o seu
            email para finalizar a compra.
          </p>
        </>
      )}
      <PaymentSelector />
      <GoForwardButton
        disabled={loading}
        addBackButton
        Icon={loading ? Loading : CartIcon}
        desktopText={loading ? '' : 'Finalizar compra'}
        mobileText={loading ? '' : 'Finalizar compra'}
      />
    </form>
  );
}
