import { ReactElement } from 'react';

export function PlusIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
      className="p-2"
    >
      <path
        stroke="#272D65"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.5 6v6m0 0v6m0-6h6m-6 0h-6"
      ></path>
    </svg>
  );
}
