import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../components/Button';
import { PageHelmet } from '../components/PageHelmet';

export function ErrorPage(): ReactElement {
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <PageHelmet title="Página não encontrada | OdontoGroup" />

      <span className="text-medium-emphasis text-xl mb-4">Ops...</span>
      <span className="text-9xl text-primary-main font-bold">404</span>
      <h1 className="text-3xl text-primary-main font-bold text-center">
        Página não encontrada
      </h1>
      <Link to="/">
        <Button text="Voltar para a página principal" className="mt-20" />
      </Link>
    </div>
  );
}
