import { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { Button } from '../Button';
import Modal from '@mui/material/Modal';
import { useError } from '../../stores/useError';

interface Props {
  open?: boolean;
  title?: string;
  text?: string;
}

export function ErrorModal({ open = true, title, text }: Props): ReactElement {
  const handleClose = () => setError(false);
  const setError = useError(state => state.setError);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      //onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="flex items-center justify-center flex-col"
    >
      <Box
        className={
          'absolute flex flex-col translate-y-2/4 w-96 bg-white border-white rounded-md'
        }
      >
        <h1 id="child-modal-title" className="self-center text-2xl mt-5">
          {title != '' && title != undefined
            ? `Código ${title}`
            : 'Ocorreu um problema'}
        </h1>
        <p id="child-modal-description" className="mt-2 m-4 self-center">
          {text != '' && text != undefined
            ? `${text}`
            : 'Problema ao realizar compra'}
        </p>
        <Button
          onClick={handleClose}
          text={'Fechar'}
          className="max-[748px]:max-w-[262px] max-[748px]:w-full mx-7 mb-5"
        />
      </Box>
    </Modal>
  );
}
