import { ReactElement } from 'react';

interface Props {
  className?: string;
}

export function UniqueBilletIcon({ className }: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      fill="none"
      viewBox="0 0 31 38"
      className={`stroke-primary-main ${className}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.5 19h12m-12 8h12m4 10h-20a4 4 0 01-4-4V5a4 4 0 014-4h11.172a2 2 0 011.414.586l10.828 10.828a2 2 0 01.586 1.414V33a4 4 0 01-4 4z"
      ></path>
    </svg>
  );
}
