import { FC, ReactElement, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import clsx from 'clsx';
import { motion, Variants } from 'framer-motion';

interface Props {
  item: {
    name: string;
    href: string;
    Icon: FC<{ className?: string; width?: string; height?: string }>;
    subPages?: {
      name: string;
      href: string;
    }[];
  };
}

function checkIsActive(href: string): boolean {
  const currentUrl = window.location.pathname + window.location.search;

  if (href === currentUrl) {
    return true;
  }

  if (currentUrl.startsWith(href) && href !== '/') {
    return true;
  }

  if (currentUrl.includes(`${href}/`) && href !== '/') {
    return true;
  }

  return false;
}

const variants: Variants = {
  open: { height: 147 },
  closed: { height: 0 },
};

export function NavbarItem({ item }: Props): ReactElement {
  const [isActive, setIsActive] = useState(checkIsActive(item.href));

  const location = useLocation();

  useEffect(() => {
    setIsActive(checkIsActive(item.href));
  }, [item.href, location]);

  const [isSubPageOpen, setIsSubPageOpen] = useState(false);

  function toggleSubPages() {
    setIsSubPageOpen(prevState => !prevState);
  }

  const ParentElement = item.subPages ? 'button' : Link;

  return (
    <li
      className="relative group"
      onMouseEnter={toggleSubPages}
      onMouseLeave={toggleSubPages}
    >
      <ParentElement
        to={item.href as string}
        className={clsx(
          'group box-content h-full flex justify-center items-center gap-[6px] px-3 rounded-t-lg border-primary-main group-hover:border-b-2 group-hover:bg-primary-container group-hover:text-primary-main',
          isActive
            ? 'text-primary-main font-semibold border-b-2 stroke-primary-main'
            : 'text-high-emphasis stroke-high-emphasis group-hover:stroke-primary-main',
        )}
      >
        <item.Icon />
        <span className="block text-lg tracking-normal">{item.name}</span>
      </ParentElement>
      {item.subPages && (
        <div className="absolute min-w-[204px] h-[15px] bg-transparent left-2" />
      )}
      <motion.ul
        initial={false}
        animate={isSubPageOpen && item.subPages ? 'open' : 'closed'}
        variants={variants}
        className="absolute top-[65px] left-2 bg-white z-20 min-w-[204px] rounded-b-lg shadow-card overflow-hidden"
      >
        {item.subPages?.map(subPage => (
          <li
            key={subPage.href}
            className=" flex text-high-emphasis border-b border-b-neutral-lightest hover:bg-primary-container hover:text-primary-main"
          >
            <Link to={subPage.href} className="px-4 py-3 h-full w-full">
              {subPage.name}
            </Link>
          </li>
        ))}
      </motion.ul>
    </li>
  );
}
