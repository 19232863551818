import * as z from 'zod';

export const dependentsSchema = z.object({
  name: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  birthDate: z.optional(
    z
      .string()
      .min(1, { message: 'Campo obrigatório' })
      .regex(/^(\d{2})\/(\d{2})\/(\d{4})$/, { message: 'Data inválida' }),
  ),
  civilStatus: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  gender: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  parentage: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
  cpf: z.optional(
    z
      .string()
      .min(14, { message: 'Requer 14 caracteres' })
      .regex(/^(?!\d{11})\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/, {
        message: 'CPF inválido',
      }),
  ),
  rg: z.optional(
    z.string(),
    /* .min(1, { message: 'Campo obrigatório' }) */
    /* .regex(/^[0-9]{1,2}\.?[0-9]{3}\.?[0-9]{3}-?[0-9a-zA-Z]{1,2}$/, {
        message: 'RG inválido',
      }) */
  ),
  issuingAgency: z.optional(
    z.string().min(1, { message: 'Campo obrigatório' }),
  ),
  issuingAgencyUF: z.optional(
    z.string().min(1, { message: 'Campo obrigatório' }),
  ),
  cns: z.optional(z.string() /* .min(0, { message: 'Campo obrigatório' }) */),
  mothersName: z.optional(z.string().min(1, { message: 'Campo obrigatório' })),
});
