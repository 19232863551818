import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../Button';
import { WhatsappIcon } from '../icons/WhatsappIcon';

export function ContactSection(): ReactElement {
  return (
    <section className="bg-contact-frame bg-no-repeat bg-right relative pt-[336px] max-[1548px]:pt-14">
      <div className="absolute w-full -z-10 bg-primary-main h-[calc(100%_+_180px)] -top-[180px] max-[701px]:h-[calc(100%_+_570px)] max-[701px]:-top-[570px]" />
      <div className="min-[1168px]:w-[1120px] max-[1168px]:px-6 mx-auto h-full flex flex-col justify-end relative">
        <div className="flex items-center">
          <img
            src="/assets/images/avatars/contato.png"
            alt=""
            className="min-[1168px]:h-[484px] min-[1168px]:w-[484px] min-[701px]:h-[300px] h-[235px] object-fill max-[701px]:-ml-12"
          />
          <div className="min-[1168px]:absolute flex flex-col min-[1168px]:gap-14 gap-8 left-[430px] bottom-[119px] max-[701px]:-ml-12">
            <h2 className="min-[1168px]:text-[2.625rem] min-[701px]:text-[2rem] max-[701px]:text-xl font-bold text-white min-[1570px]:w-[905px]">
              Entre em contato para conhecer planos para mais de 199 vidas
            </h2>
            <div>
              <Button
                text={
                  <Link
                    to={`https://wa.me/${process.env.REACT_APP_CONTACT_CAC}?text=Quero contratar um plano odontológico!`}
                    target="_blank"
                  >
                    Ir para o Whatsapp
                  </Link>
                }
                intent="quaternary"
                Icon={WhatsappIcon}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
