import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  icon: string;
  text: string;
  href?: string;
  useAnchor?: boolean;
}

export default function QuickAccessButton({
  icon,
  text,
  useAnchor = false,
  href = 'https://odontogroup.s4e.com.br/sys/?TipoUsuario=1',
}: Props): ReactElement {
  const Item = useAnchor ? 'a' : Link;
  return (
    <Item
      to={(useAnchor ? undefined : href) as any}
      href={useAnchor ? href : undefined}
      className="shadow-card p-4 rounded-lg flex flex-col gap-3 justify-center items-center min-[1000px]:max-w-[235px] cursor-pointer max-[999px]:flex-row max-[999px]:w-full max-[999px]:justify-start"
    >
      {/* <img src={icon} className="max-[999px]:size-[32rem]" /> */}
      <img src={icon} className="max-[999px]:h-[58px]" />
      <p className="text-primary-main text-2xl max-[999px]:text-lg max-[999px]:text-left">
        {text}
      </p>
    </Item>
  );
}
