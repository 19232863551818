import { ReactElement } from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;
}

export function AnnotationIcon({
  width = '13',
  height = '12',
  className,
}: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 13 12"
    >
      <path
        className={className}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.167 3.333h6.666M3.167 5.999h2.666m.667 5.334L3.833 8.666h-2A1.333 1.333 0 01.5 7.333V1.999A1.333 1.333 0 011.833.666h9.334A1.333 1.333 0 0112.5 1.999v5.334a1.334 1.334 0 01-1.333 1.333h-2L6.5 11.333z"
      ></path>
    </svg>
  );
}
