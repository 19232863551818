import { ReactElement } from 'react';

interface Props {
  title: string;
  description: string;
  detail?: string;
}

export function FooterInformationText({
  title,
  description,
  detail = '',
}: Props): ReactElement {
  return (
    <div className="flex flex-col">
      <span className="text-sm text-[#FCFCFC]">{title}</span>
      <span className="text-semibold">
        {description}
        <div className="inline text-normal text-xs">{detail}</div>
      </span>
    </div>
  );
}
