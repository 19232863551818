import { ReactElement } from 'react';

import clsx from 'clsx';

interface Props {
  className?: string;
}

export function CaretDownIcon({ className }: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={clsx(className || 'stroke-[#9AA4AC]')}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 9l-7 7-7-7"
      ></path>
    </svg>
  );
}
