import { FC, LiHTMLAttributes, ReactElement } from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';

const styles = cva(
  'w-full rounded-lg flex items-center shadow-card h-[100px]',
  {
    variants: {
      active: {
        true: 'text-white bg-primary-main',
        false: 'text-primary-main bg-white',
      },
    },
  },
);

interface Props
  extends VariantProps<typeof styles>,
    LiHTMLAttributes<HTMLLIElement> {
  label: string;
  onClick: () => void;
  Icon: FC<{ className?: string }>;
}

export function PaymentSelectorItem({
  active = false,
  label,
  onClick,
  Icon,
  ...props
}: Props): ReactElement {
  return (
    <li className={styles({ active })} {...props}>
      <button
        type="button"
        onClick={onClick}
        className="w-full py-3 px-4 h-full flex items-center gap-2"
      >
        <Icon
          className={`min-w-[30px] ${clsx(
            active ? 'stroke-white' : 'stoke-primary-main',
          )}`}
        />
        <span>{label}</span>
      </button>
    </li>
  );
}
