import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from './Footer';
import { Header } from './Header';
import { WindowHandlerWrapper } from './WindowHandlerWrapper';

export function RootLayout(): ReactElement {
  return (
    <>
      <Header />
      <main>
        <WindowHandlerWrapper>
          <Outlet />
        </WindowHandlerWrapper>
      </main>
      <Footer />
    </>
  );
}
