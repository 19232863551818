import { ReactElement } from 'react';

import { useWindowSizeStore } from '../../../stores/useWindowSizeStore';
import { MainPurchaseSummaryContent } from './MainPurchaseSummaryContent';

interface Props {
  isPurchaseSummaryOpen?: boolean;
}

export function ContentHandlerPurchaseSummary({
  isPurchaseSummaryOpen,
}: Props): ReactElement {
  const width = useWindowSizeStore(state => state.width);

  if (width >= 748) {
    return <MainPurchaseSummaryContent />;
  }

  if (isPurchaseSummaryOpen) {
    return <MainPurchaseSummaryContent />;
  }

  return <></>;
}
