import { ReactElement } from 'react';

interface Props {
  className?: string;
}

export function CreditCardIcon({ className }: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 39 30"
      className={`stroke-primary-main ${className}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.5 11h36m-28 10h2m8 0h2m-14 8h24a6 6 0 006-6V7a6 6 0 00-6-6h-24a6 6 0 00-6 6v16a6 6 0 006 6z"
      ></path>
    </svg>
  );
}
