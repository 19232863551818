import { ReactElement } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
}

export function LeadingIcon({
  width = '19',
  height = '14',
  color = '#272D65',
  className,
}: Props): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 14"
      className={className}
    >
      <path fill={color} d="M10 9a2 2 0 100-4 2 2 0 000 4z"></path>
      <path
        fill={color}
        fillRule="evenodd"
        d="M.458 7C1.732 2.943 5.522 0 10 0s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 11.057.458 7zM14 7a4 4 0 11-8 0 4 4 0 018 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
